.spf-has-filter.spf-layout-app #gf-products {
    gap: 0 !IMPORTANT;
}
  .sort-by-toggle span {
    line-height: 38px;
}
.gf-block-title h4 span, .gf-block-title .h4 span, .gf-form-input-inner label span {
    font-size: 16px !important;
    color: #3a3a3a !important;
    text-transform: uppercase !important;
    font-weight: bold !important;
    font-family: roboto;
}
  #gf-tree .gf-label{
    font-size: 16px !important;
    font-family: roboto;
    font-weight:400;
  }